import React from "react";
import classes from "./Input.module.scss";
import { returnFirstError } from "../../../utils/validations/validations";

const Input = (props) => {
  if (props.type === "textarea") {
    return (
      <div
        className={
          !props.inputValues.inputValid && props.inputValues.isDirty
            ? classes.textareaError
            : ""
        }
      >
        <textarea
          onChange={props.inputValues.onChange}
          onBlur={props.inputValues.onBlur}
          value={props.inputValues.value}
          placeholder={props.placeholder}
          className={
            !props.inputValues.inputValid && props.inputValues.isDirty
              ? [classes.textarea, classes.error, "font-16"].join(" ")
              : [classes.textarea, "font-16"].join(" ")
          }
        ></textarea>
      </div>
    );
  }

  return (
    <input
      onChange={props.inputValues.onChange}
      onBlur={props.inputValues.onBlur}
      value={props.inputValues.value}
      type={props.eyeHidden === true ? "number" : "text"}
      placeholder={props.placeholder}
      className={
        !props.inputValues.inputValid && props.inputValues.isDirty
          ? [classes.input, classes.error, "font-16"].join(" ")
          : [classes.input, "font-16"].join(" ")
      }
    />
  );
  {
    /* {props.inputValues.isDirty && returnFirstError(props.inputValues.valid)} */
  }
  {
    /* <ErrorMsg>{returnFirstError(props.inputValues.valid)}</ErrorMsg> */
  }
};

export default Input;
