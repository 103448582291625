import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";

import { Link, useLocation } from "react-router-dom";

import { headerRoutes } from "../../../router";

import $ from "jquery";
import logo from "../../../assets/img/logo.png";

const Header = () => {
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();

  const headerRef = useRef(null);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
      $(headerRef.current).removeClass(classes.lightBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
      $(headerRef.current).removeClass(classes.lightBg);
    }
  };

  useEffect(() => {
    listenScroll();
  }, [burgerOpened]);
  useEffect(() => {
    $(headerRef.current).removeClass(classes.lightBg);
    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    listenScroll();
  }, [pathname]);
  useEffect(() => {
    setBurgerOpened(false);
  }, [pathname]);
  useEffect(() => {
    if (burgerOpened) {
      $("body").addClass("lock");
    } else {
      $("body").removeClass("lock");
    }
  }, [burgerOpened]);
  useEffect(() => {
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });

  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} ${
        classes.hideBg
      } no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link to={`/`} onClick={() => setBurgerOpened(false)}>
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className={classes.headerRowLinks}>
            {headerRoutes.map((route) => (
              <Link
                key={route.id}
                to={`/${route.path}`}
                className={`${
                  route.path === _.last(pathname.split("/")) &&
                  classes.linkActive
                }`}
              >
                {route.text}
              </Link>
            ))}
          </div>
          <div className={classes.headerRowRight}>
            {/* <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
            <a href="#" className={classes.login}>
              Log in
            </a> */}
            <div
              onClick={() => setBurgerOpened((value) => !value)}
              className={`${classes.burger} ${
                burgerOpened && classes.burgerActive
              }`}
            ></div>
          </div>
        </div>
      </div>
      <div
        className={`${classes.mobileMenu} ${
          burgerOpened && classes.mobileMenuActive
        }`}
      >
        <div className="container">
          {headerRoutes.map((route) => (
            <Link
              key={route.id}
              to={`/${route.path}`}
              className={`${
                route.path === _.last(pathname.split("/")) && classes.linkActive
              } ${classes.aLink}`}
              onClick={() => setBurgerOpened(false)}
            >
              {route.text}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
