import React from "react";
import classes from "./Footer.module.scss";
import img1 from "../../../assets/img/logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className={[classes.footer, ""].join(" ")}>
      <div className={[classes.footerBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.footerRow, ""].join(" ")}>
            <div className={[classes.footerRowLeft, ""].join(" ")}>
              <Link to="/">
                <img src={img1} alt="" />
              </Link>
            </div>
            <div className={[classes.footerRowRight, ""].join(" ")}>
              <div className={[classes.footerRowRightContacts, ""].join(" ")}>
                <h4
                  className={[
                    classes.footerRowRightContactsTitle,
                    "font-16",
                  ].join(" ")}
                >
                  Наші Контакти
                </h4>
                <a
                  href={"tel:380634849317"}
                  className={[
                    classes.footerRowRightContactsText,
                    "font-16",
                  ].join(" ")}
                >
                  +380 63 484 93 17
                </a>
                <p
                  className={[
                    classes.footerRowRightContactsText,
                    "font-16",
                  ].join(" ")}
                >
                  м.Київ
                </p>
                <a
                  className={[
                    classes.footerRowRightContactsText,
                    "font-16",
                  ].join(" ")}
                  href="mailto:bnbsolution2021@gmail.com"
                >
                  bnbsolution2021@gmail.com
                </a>
              </div>
              <div className={[classes.footerRowRightShedule, ""].join(" ")}>
                <h4
                  className={[
                    classes.footerRowRightSheduleTitle,
                    "font-16",
                  ].join(" ")}
                >
                  Графік Роботи
                </h4>
                <p
                  className={[
                    classes.footerRowRightSheduleText,
                    "font-16",
                  ].join(" ")}
                >
                  09:00 — 17:00 (пн-чт)
                </p>
                <p
                  className={[
                    classes.footerRowRightSheduleText,
                    "font-16",
                  ].join(" ")}
                >
                  09:00 — 16:00 (пт)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
