import HomePage from '../pages/HomePage/HomePage';
import CandidatesPage from '../pages/CandidatesPage/CandidatesPage';
import EmployersPage from '../pages/EmployersPage/EmployersPage';


export const routes = [
    { path: '*', element: HomePage },
    { path: '/candidates', element: CandidatesPage },
    { path: '/employers', element: EmployersPage },

];

export const headerRoutes = [
    { id: 1, path: 'employers', text: 'Роботодавцям' },
    { id: 2, path: 'candidates', text: 'Кандидатам' },

];
