import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import First from "../../components/CandidatesComponents/First/First";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import classes from "./CandidatesPage.module.scss";
import ChooseAccount from "../../components/CandidatesComponents/ChooseAccount/ChooseAccount";
import Second from "../../components/CandidatesComponents/Second/Second";
import Three from "../../components/CandidatesComponents/Three/Three";
const CandidatesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Кандидатам</title>
        <meta name="title" content="Що ми пропонуємо кандидатам" />
        <meta property="og:title" content="Що ми пропонуємо кандидатам" />
        <meta property="twitter:title" content="Що ми пропонуємо кандидатам" />

        <meta
          name="description"
          content="У рекрутинговому агенстві BNB Solution ми прагнемо створити надійне та позитивне середовище для кандидатів, де ви можете знайти свою ідеальну роботу та розвиватись професійно. Наша команда експертів займається пошуком та підбором талановитих працівників для різних індустрій і галузей, щоб забезпечити вам найкращі можливості працевлаштування."
        />
        <meta
          property="og:description"
          content="У рекрутинговому агенстві BNB Solution ми прагнемо створити надійне та позитивне середовище для кандидатів, де ви можете знайти свою ідеальну роботу та розвиватись професійно. Наша команда експертів займається пошуком та підбором талановитих працівників для різних індустрій і галузей, щоб забезпечити вам найкращі можливості працевлаштування."
        />
        <meta
          property="twitter:description"
          content="У рекрутинговому агенстві BNB Solution ми прагнемо створити надійне та позитивне середовище для кандидатів, де ви можете знайти свою ідеальну роботу та розвиватись професійно. Наша команда експертів займається пошуком та підбором талановитих працівників для різних індустрій і галузей, щоб забезпечити вам найкращі можливості працевлаштування."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className={classes.page}>
        <Header />
        <First />
        <ChooseAccount />
        <Second />
        <Three />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default CandidatesPage;
