import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import First from "../../components/HomePage/First/First";
import Second from "../../components/HomePage/Second/Second";
import Three from "../../components/HomePage/Three/Three";
import Four from "../../components/HomePage/Four/Four";
import ChooseAccount from "../../components/HomePage/ChooseAccount/ChooseAccount";
import Five from "../../components/HomePage/Five/Five";
import Footer from "../../components/GlobalComponents/Footer/Footer";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Головна Сторінка</title>
        <meta name="title" content="BNB Solution" />
        <meta property="og:title" content="BNB Solution" />
        <meta property="twitter:title" content="BNB Solution" />

        <meta
          name="description"
          content="BNB Solution — провідна рекрутингова агенція, що спеціалізується на пошуку та підборі висококваліфікованих спеціалістів для різноманітних галузей та секторів бізнесу."
        />
        <meta
          property="og:description"
          content="BNB Solution — провідна рекрутингова агенція, що спеціалізується на пошуку та підборі висококваліфікованих спеціалістів для різноманітних галузей та секторів бізнесу."
        />
        <meta
          property="twitter:description"
          content="BNB Solution — провідна рекрутингова агенція, що спеціалізується на пошуку та підборі висококваліфікованих спеціалістів для різноманітних галузей та секторів бізнесу."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <First />
        <Second />
        <Three />
        <Four />
        <ChooseAccount />
        <Five />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
