import { useEffect, useState, useRef } from "react";
import classes from "./First.module.scss";
import img1 from "../../../assets/img/HomePage/1/1.png";
import imgBg from "../../../assets/img/HomePage/1/bg.png";
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const First = () => {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/employers");
    setTimeout(() => {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#mainForm").offset().top,
        },
        2000
      );
    }, 200);
  }
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        x: 0,
        duration: 1,
        delay: 0.3,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.first, ""].join(" ")} ref={col1}>
      <div className={[classes.firstBg, ""].join(" ")}>
        <img src={imgBg} alt="BNB Solution:" />
      </div>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.firstRow, ""].join(" ")}>
            <div className={[classes.firstRowLeft, ""].join(" ")}>
              <h1 className={[classes.firstTitle, "font-64"].join(" ")}>
                BNB Solution:
              </h1>
              <p className={[classes.firstSubtitle, "font-36"].join(" ")}>
                Ваш партнер у пошуку та підборі кваліфікованих фахівців
              </p>
              <p className={[classes.firstText, "font-16"].join(" ")}>
                BNB Solution — провідна рекрутингова агенція, що спеціалізується
                на пошуку та підборі висококваліфікованих спеціалістів для
                різноманітних галузей та секторів бізнесу.
              </p>
              <div
                className={[classes.firstBtn, ""].join(" ")}
                onClick={() => {
                  handleClick();
                }}
              >
                <Button>Замовити консультацію</Button>
              </div>
            </div>
            <div
              className={[classes.firstRowRight, ""].join(" ")}
              ref={monetRef1}
            >
              <img src={img1} alt="BNB Solution:" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
