import { useEffect, useState, useRef } from "react";
import classes from "./Three.module.scss";
import img1 from "../../../assets/img/HomePage/3/1.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Three = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        x: 0,

        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.three, ""].join(" ")} ref={col1}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeRow, ""].join(" ")}>
            <div className={[classes.threeRowLeft, ""].join(" ")}>
              <h2 className={[classes.threeRowLeftTitle, "font-40"].join(" ")}>
                Про Нас
              </h2>
              <p className={[classes.threeRowLeftText, "font-16"].join(" ")}>
                Ми пропонуємо інноваційні рішення в галузі рекрутингу, які
                допомагають нашим клієнтам залучати і наймати найталановитіших
                кандидатів.
              </p>
              <p className={[classes.threeRowLeftText, "font-16"].join(" ")}>
                Наша команда складається з досвідчених і відданих професіоналів,
                які ретельно аналізують потреби кожного клієнта і розробляють
                індивідуальні стратегії пошуку. Ми ретельно перевіряємо кожного
                кандидата, щоб переконатися в їхній відповідності вимогам і
                якості, яку ми прагнемо надати нашим клієнтам.
              </p>
              <p className={[classes.threeRowLeftText, "font-16"].join(" ")}>
                BNB Solution пропонує широкий спектр послуг, включно з
                рекрутингом на постійні та тимчасові позиції, пошуком керівних
                кадрів, консультаціями щодо стратегій залучення талантів і
                багато іншого.
              </p>
            </div>
            <div
              className={[classes.threeRowRight, ""].join(" ")}
              ref={monetRef1}
            >
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
