import { useEffect, useState, useRef } from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/Candidates/1/1.png";
import gsap from "gsap";
import $ from "jquery";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const First = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        x: 0,
        duration: 1,
        delay: 0.3,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.first, "mb mt"].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.firstRow, ""].join(" ")}>
            <div className={[classes.firstRowLeft, ""].join(" ")}>
              <h2 className={[classes.firstRowLeftTitle, "font-36"].join(" ")}>
                Що ми пропонуємо кандидатам
              </h2>
              <p className={[classes.firstRowLeftText, "font-16"].join(" ")}>
                У рекрутинговому агенстві BNB Solution ми прагнемо створити
                надійне та позитивне середовище для кандидатів, де ви можете
                знайти свою ідеальну роботу та розвиватись професійно.
              </p>
              <p className={[classes.firstRowLeftText, "font-16"].join(" ")}>
                Наша команда експертів займається пошуком та підбором
                талановитих працівників для різних індустрій і галузей, щоб
                забезпечити вам найкращі можливості працевлаштування.
              </p>
              <div
                className={[classes.firstRowLeftBtn, ""].join(" ")}
                onClick={() => {
                  $([document.documentElement, document.body]).animate(
                    {
                      scrollTop: $("#mainForm").offset().top,
                    },
                    2000
                  );
                }}
              >
                <Button>Замовити консультацію</Button>
              </div>
            </div>
            <div
              className={[classes.firstRowRight, ""].join(" ")}
              ref={monetRef1}
            >
              <img src={img1} alt="Що ми пропонуємо кандидатам" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
