import React from "react";
import classes from "./Three.module.scss";
import { useState } from "react";
import { useInput } from "../../../hooks/useInput";
import Input from "../../GlobalComponents/Input/Input";
import axios from "axios";
const Three = () => {
  const name = useInput("", { isEmpty: true, minLength: 3 });
  const phone = useInput("", { isEmpty: true, minLength: 6, phone: false });
  const sphere = useInput("", { isEmpty: true, minLength: 3 });
  const comment = useInput("", { isEmpty: true, minLength: 3 });
  const [hideForm, setHideForm] = useState(false);

  const onSend = async () => {
    const sendObj = {
      siteName: "BNB Solution",
      name: name.value,
      sphere: sphere.value,
      phone: phone.value,
      comment: comment.value,
    };
    // console.log(sendObj);

    if (
      !name.inputValid ||
      !sphere.inputValid ||
      !phone.inputValid ||
      !comment.inputValid
    ) {
      name.setDirty(true);
      sphere.setDirty(true);
      phone.setDirty(true);
      comment.setDirty(true);
    } else {
      // let resObj = JSON.stringify(sendObj);
      //nftella.org/XAp4T9mdCFvxsr83.php
      // console.log(resObj);
      axios
        .post("https://civbt.xyz/api/bot/QjL5VzpES0", sendObj)
        .then((response) => {
          console.log(response);
          name.setValue("");
          name.inputValid = true;
          name.setDirty(false);
          sphere.setValue("");
          sphere.inputValid = true;
          sphere.setDirty(false);
          phone.setValue("");
          phone.inputValid = true;
          phone.setDirty(false);
          comment.setValue("");
          comment.inputValid = true;
          comment.setDirty(false);
          setHideForm(true);
          setTimeout(() => {
            setHideForm(false);
          }, 5000);
        })
        .catch((err) => console.log(err));

      // fetch("https://civbt.xyz/api/bot/QjL5VzpES0", {
      //   method: "POST",
      //   // mode: "no-cors",
      //   // headers: new Headers({
      //   //   "Content-Type": "application/json",
      //   //   Accept: "application/json",
      //   // }),
      //   body: sendObj,
      // })
      //   .then((response) => {
      //     console.log(response);
      //     name.setValue("");
      //     name.inputValid = true;
      //     name.setDirty(false);
      //     sphere.setValue("");
      //     sphere.inputValid = true;
      //     sphere.setDirty(false);
      //     phone.setValue("");
      //     phone.inputValid = true;
      //     phone.setDirty(false);
      //     comment.setValue("");
      //     comment.inputValid = true;
      //     comment.setDirty(false);
      //     setHideForm(true);
      //     setTimeout(() => {
      //       setHideForm(false);
      //     }, 5000);
      //   })
      //   .catch((err) => console.log(err));
    }
  };

  return (
    <div className={[classes.three, "mb"].join(" ")} id="mainForm">
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.threeTitle, "font-36"].join(" ")}>
            Заповнюйте заявку і наші менеджеру зв’яжуться з вами протягом 15
            хвилин!
          </h2>
          <div className={[classes.threeForm, ""].join(" ")}>
            <div className={[classes.threeFormInput, ""].join(" ")}>
              <Input
                type="text"
                placeholder="Ваше Ім’я"
                inputValues={name}
                backInput={false}
              />
            </div>
            <div className={[classes.threeFormInput, ""].join(" ")}>
              <Input
                type="text"
                placeholder="Номер телефону"
                inputValues={phone}
                backInput={false}
              />
            </div>
            <div className={[classes.threeFormInput, ""].join(" ")}>
              <Input
                type="text"
                placeholder="Сфера пошуку вакансій"
                inputValues={sphere}
                backInput={false}
              />
            </div>
            <div className={[classes.threeFormTextarea, ""].join(" ")}>
              <Input
                type="textarea"
                placeholder="Коментар"
                inputValues={comment}
                backInput={false}
              />
            </div>
            <div className={[classes.threeFormBtn, ""].join(" ")}>
              <button
                onClick={() => {
                  onSend();
                }}
                className="font-16"
              >
                Залишити заявку
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
