import { useEffect, useState, useRef } from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/Candidates/3/1.png";
import img2 from "../../../assets/img/Candidates/3/2.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Second = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        x: 0,

        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.second, "mb"].join(" ")} ref={col1}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowLeft, ""].join(" ")}>
              <h2 className={[classes.secondRowLeftTitle, "font-36"].join(" ")}>
                Найбільш затребувані вакансії  2023
              </h2>
              <ul className={[classes.secondRowLeftList, ""].join(" ")}>
                <li
                  className={[classes.secondRowLeftListItem, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img2} alt="" />
                  Програміст/розробник програмного забезпечення
                </li>
                <li
                  className={[classes.secondRowLeftListItem, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img2} alt="" />
                  Аналітик даних
                </li>
                <li
                  className={[classes.secondRowLeftListItem, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img2} alt="" />
                  Маркетолог
                </li>
                <li
                  className={[classes.secondRowLeftListItem, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img2} alt="" />
                  Менеджер проекту
                </li>
                <li
                  className={[classes.secondRowLeftListItem, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img2} alt="" />
                  Інженер-конструктор
                </li>
                <li
                  className={[classes.secondRowLeftListItem, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img2} alt="" />
                  Графічний дизайнер
                </li>
                <li
                  className={[classes.secondRowLeftListItem, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img2} alt="" />
                  Системний адміністратор
                </li>
                <li
                  className={[classes.secondRowLeftListItem, "font-16"].join(
                    " "
                  )}
                >
                  <img src={img2} alt="" />
                  Бізнес-аналітик
                </li>
              </ul>
            </div>
            <div
              className={[classes.secondRowRight, ""].join(" ")}
              ref={monetRef1}
            >
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
