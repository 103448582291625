import { useEffect, useState, useRef } from "react";
import classes from "./ChooseAccount.module.scss";
import img1 from "../../../assets/img/HomePage/5/1.png";
import img2 from "../../../assets/img/HomePage/5/2.png";
import img3 from "../../../assets/img/HomePage/5/3.png";
import img4 from "../../../assets/img/HomePage/5/4.png";
import img5 from "../../../assets/img/HomePage/5/5.png";
import arrow from "../../../assets/img/traders/right.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ChooseAccount = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const mainRef = useRef(null);

  const slideRef1 = useRef(null);
  const slideRef2 = useRef(null);
  const slideRef3 = useRef(null);
  const slideRef4 = useRef(null);
  const slideRef5 = useRef(null);

  const cards = [
    {
      id: 1,
      image: img1,
      title: "Збирання побажань",
      ref: slideRef1,
      desc: "Наша команда ретельно спілкується з клієнтом, щоб повністю зрозуміти їхні потреби та вимоги до вакансії. Ми обговорюємо не тільки технічні навички, а й культурні аспекти, які важливі для успішної інтеграції кандидата в компанію.",
    },
    {
      id: 2,
      image: img2,
      title: "Перегляд резюме та попередня співбесіда",
      ref: slideRef2,
      desc: "Ми аналізуємо резюме та проводимо попередні співбесіди з потенційними кандидатами. На цьому етапі ми оцінюємо їхні професійні навички, досвід роботи та відповідність вимогам вакансії.",
    },
    {
      id: 3,
      image: img3,
      title: "Тестування та оцінювання",
      ref: slideRef3,
      desc: "За необхідності ми проводимо спеціальні тести й оцінювання, щоб більш детально оцінити технічні навички та кваліфікацію кандидата. Це може включати технічні завдання, ассессмент або інші методи оцінки, що залежать від вимог вакансії.",
    },
    {
      id: 4,
      image: img4,
      title: "Співбесіда з клієнтом",
      ref: slideRef4,
      desc: "Після проходження попереднього відбору, ми організуємо співбесіду кандидатів з клієнтом. Ми допомагаємо встановити зустріч, підготувати питання і забезпечити ефективну взаємодію між сторонами.",
    },
    {
      id: 5,
      image: img5,
      title: "Підтримка в процесі найму",
      ref: slideRef5,
      desc: "Після вибору відповідного кандидата ми підтримуємо клієнта в процесі найму, надаючи рекомендації щодо умов праці.",
    },
  ];

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(slideRef1.current, {
        y: 0,
        delay: 0.3,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 1,
        },
      });
      gsap.timeline().to(slideRef2.current, {
        y: 0,
        delay: 0.5,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 2,
        },
      });
      gsap.timeline().to(slideRef3.current, {
        y: 0,
        delay: 0.7,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 3,
        },
      });
      gsap.timeline().to(slideRef4.current, {
        y: 0,
        delay: 0.9,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 4,
        },
      });
      gsap.timeline().to(slideRef5.current, {
        y: 0,
        delay: 1.1,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 5,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <section
      className={[classes.chooseAccount, "mt mb"].join(" ")}
      ref={mainRef}
    >
      <div className="container">
        <div className={[classes.chooseAccountRow, ""].join(" ")}>
          <div className={[classes.chooseAccountRowLeft, ""].join(" ")}>
            <h2
              className={[classes.chooseAccountRowLeftTitle, "font-40"].join(
                " "
              )}
            >
              Процес підбору кандидата в BNB Solution
            </h2>
            <p
              className={[classes.chooseAccountRowLeftSubTitle, "font-16"].join(
                " "
              )}
            >
              Процес підбору фахівця в рекрутинговій агенції BNB Solution
              ґрунтується на систематичному й уважному підході, який гарантує
              знаходження оптимального кандидата для кожної вакансії. Ось
              загальний огляд нашого процесу підбору фахівця:
            </p>
          </div>
          <div className={[classes.chooseAccountRowRight, ""].join(" ")}>
            <div className={classes.pagination}>
              <img
                ref={prevRef}
                className={classes.pagPrev}
                src={arrow}
                alt=""
              />
              <img
                ref={nextRef}
                className={classes.pagNext}
                src={arrow}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={classes.swiperWr}>
          <Swiper
            modules={[Pagination, Navigation]}
            className="chooseAccountTypeSwiper"
            spaceBetween={20}
            slidesPerView={"auto"}
            pagination={true}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }}
          >
            {cards.map((card) => (
              <SwiperSlide
                key={card.id}
                className="chooseAccountTypeSwiperSlide"
              >
                <div className={classes.card} ref={card.ref}>
                  <div className={[classes.cardItem, ""].join(" ")}>
                    <div className={[classes.cardItemImg, ""].join(" ")}>
                      <img src={card.image} alt="" />
                    </div>
                    <h2
                      className={[classes.cardItemTitle, "font-24"].join(" ")}
                    >
                      {card.title}
                    </h2>
                    <p className={[classes.cardItemDesc, "font-16"].join(" ")}>
                      {card.desc}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ChooseAccount;
