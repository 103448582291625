import React, { useEffect } from "react";
import img1 from "../../../assets/img/Employers/2/1.png";
import img2 from "../../../assets/img/Employers/2/2.png";
import img3 from "../../../assets/img/Employers/2/3.png";
import classes from "./Slider.module.scss";
import imgLeft from "../../../assets/img/HomePage/slider/left.png";
import imgRight from "../../../assets/img/HomePage/slider/right.png";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
// import { Fab } from "@material-ui/core";
// import testlefticon from "../assets/pictures/testlefticon.svg"
// import testrightiicon from "../assets/pictures/testrightiicon.svg"

import "./styles.scss";
import { Slide } from "./testimonial.js";
// import pic1 from "../assets/pictures/our-clients/pic1.png"
// import pic2 from "../assets/pictures/our-clients/pic2.png"
// import pic3 from "../assets/pictures/our-clients/pic3.png"
// import pic4 from "../assets/pictures/our-clients/pic4.png"

const data = [
  {
    image: img1,
    text: '"Ми були дуже задоволені співпрацею з рекрутинговим агентством BNB Solution. Вони виявили глибоке розуміння наших потреб та вимог і зуміли залучити відмінних кандидатів для нашої компанії. Їх професіоналізм, експертиза та швидкість реакції вражаючі. Дякуємо команді BNB Solution за великий вклад у розвиток нашої організації."',
    name: "Юлія Смирнова",
    star: 95,
    subname: "online - business marketer",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img2,
    text: '"BNB Solution став нашим надійним партнером в процесі найму нових співробітників. Вони проявили велику ретельність у відборі кандидатів, детально вивчаючи нашу корпоративну культуру та вимоги до посад. Кожен запропонований кандидат був добре підготовлений та відповідав нашим очікуванням. Ми рекомендуємо BNB Solution як надійного партнера для рекрутингу висококваліфікованих співробітників."',
    name: "Максим Соколов",
    star: 95,
    subname: "website’s advertiser",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img3,
    text: '"Завдяки співпраці з рекрутинговим агентством BNB Solution ми зекономили значний час та зусилля у пошуку талановитих фахівців. Їх команда проявила високий рівень професіоналізму та ефективності в пропозиції потрібних кандидатів для наших вакансій. Ми вдячні BNB Solution за їхню допомогу у формуванні сильної та конкурентоспроможної команди."',
    name: "Олексій Ковальчук",
    star: 95,
    subname: "business marketer",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
];

const Testimonial = () => {
  const ref = React.useRef(StackedCarousel);
  useEffect(() => {
    // setInterval(stuff, 6000);
    // console.log("i fire once");
  }, []);

  function stuff() {
    ref.current?.goNext();
  }

  return (
    <div className={[classes.slider, ""].join(" ")}>
      <h3 className={[classes.title, "font-36"].join(" ")}>
        Відгуки Наших Клієнтів
      </h3>

      <div className="card card-carrier">
        <div style={{ position: "relative" }}>
          <ResponsiveContainer
            carouselRef={ref}
            render={(width, carouselRef) => {
              let currentVisibleSlide = 3;
              if (width <= 1280) currentVisibleSlide = 3;
              if (width <= 720) currentVisibleSlide = 1;
              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={Slide}
                  slideWidth={width > 1220 ? 750 : 500}
                  carouselWidth={width}
                  data={data}
                  maxVisibleSlide={3}
                  customScales={[
                    1, 0.65,

                    0.8,
                  ]}
                  transitionTime={450}
                  currentVisibleSlide={currentVisibleSlide}
                />
              );
            }}
          />
          <div className={[classes.arrows, ""].join(" ")}>
            <div
              className={[classes.arrowsLeft, ""].join(" ")}
              onClick={() => ref.current?.goBack()}
            >
              <img src={imgLeft} alt="" />
            </div>
            <div
              className={[classes.arrowsRight, ""].join(" ")}
              onClick={() => ref.current?.goNext()}
            >
              <img src={imgRight} alt="" />
            </div>
          </div>

          {/* <div
          className="card-button testimonial-left-button"
          size="small"
          onClick={() => ref.current?.goBack()}
        >
          left
        </div>
        <div
          className="card-button testimonial-right-button"
          size="small"
          onClick={() => ref.current?.goNext()}
        >
          right
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
