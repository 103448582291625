import { useEffect, useState, useRef } from "react";
import classes from "./Five.module.scss";
import img1 from "../../../assets/img/HomePage/6/1.png";
import img2 from "../../../assets/img/HomePage/6/2.png";
import img3 from "../../../assets/img/HomePage/6/3.png";
import img4 from "../../../assets/img/HomePage/6/4.png";
import imgBg from "../../../assets/img/HomePage/6/bg.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Five = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        x: 0,

        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 8,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.five, "mb"].join(" ")} ref={col1}>
      <div className={[classes.fiveImg, ""].join(" ")}>
        <img src={imgBg} alt="Переваги Рекрутерів над HR - спеціалістами" />
      </div>

      <div className={[classes.fiveBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fiveRow, ""].join(" ")}>
            <div className={[classes.fiveRowLeft, ""].join(" ")}>
              <div className={[classes.fiveRowLeftImg, ""].join(" ")}>
                <img
                  src={img4}
                  ref={monetRef1}
                  alt="Переваги Рекрутерів над HR - спеціалістами"
                />
              </div>

              <h3 className={[classes.fiveRowLeftTitle, "font-40"].join(" ")}>
                Переваги Рекрутерів над HR - спеціалістами
              </h3>
              <p className={[classes.fiveRowLeftText, "font-20"].join(" ")}>
                Завдяки цій методиці ми закривали всі вакансії, навіть найбільш
                неординарні з специфічними умовами.
              </p>
            </div>
            <div className={[classes.fiveRowRight, ""].join(" ")}>
              <div className={[classes.fiveRowRightItem, ""].join(" ")}>
                <div className={[classes.fiveRowRightItemImg, ""].join(" ")}>
                  <img
                    src={img1}
                    alt="Переваги Рекрутерів над HR - спеціалістами"
                  />
                </div>
                <h3
                  className={[classes.fiveRowRightItemTitle, "font-24"].join(
                    " "
                  )}
                >
                  Спеціалізація в наймі
                </h3>
                <p
                  className={[classes.fiveRowRightItemSubTitle, "font-16"].join(
                    " "
                  )}
                >
                  Наші рекрутери  мають спеціалізацію та експертизу в області
                  найму співробітників. Вони знають ринок праці, мають доступ до
                  рекрутингових інструментів та мережі можуть ефективно шукати
                  та привертати таланти.
                </p>
              </div>
              <div className={[classes.fiveRowRightItem, ""].join(" ")}>
                <div className={[classes.fiveRowRightItemImg, ""].join(" ")}>
                  <img
                    src={img2}
                    alt="Переваги Рекрутерів над HR - спеціалістами"
                  />
                </div>
                <h3
                  className={[classes.fiveRowRightItemTitle, "font-24"].join(
                    " "
                  )}
                >
                  Ефективність процесу найму
                </h3>
                <p
                  className={[classes.fiveRowRightItemSubTitle, "font-16"].join(
                    " "
                  )}
                >
                  Ми займаємось всім процесом найму, починаючи з пошуку
                  кандидатів і закінчуючи підготовкою документів для прийому на
                  роботу. Ми спрямовані на пошук та відбір кращих кандидатів,
                  тим самим збільшуючи ефективність процесу найму та знижуючи
                  час, необхідний для заповнення вакансій.
                </p>
              </div>
              <div className={[classes.fiveRowRightItem, ""].join(" ")}>
                <div className={[classes.fiveRowRightItemImg, ""].join(" ")}>
                  <img
                    src={img3}
                    alt="Переваги Рекрутерів над HR - спеціалістами"
                  />
                </div>
                <h3
                  className={[classes.fiveRowRightItemTitle, "font-24"].join(
                    " "
                  )}
                >
                  Спеціалізовані знання ринку праці
                </h3>
                <p
                  className={[classes.fiveRowRightItemSubTitle, "font-16"].join(
                    " "
                  )}
                >
                  Наші рекрутери знають все про ринок праці, включаючи тренди в
                  області зайнятості, заробітну плату, вимоги до кандидатів
                  тощо. Це дозволяє нам знаходити кандидатів з потрібними
                  навичками і талантами, а також пропонувати конкурентоспроможні
                  умови для залучення талантів.
                </p>
              </div>
            </div>
          </div>
          <div className={[classes.fiveMobImg, ""].join(" ")}>
            <img src={img4} alt="Переваги Рекрутерів над HR - спеціалістами" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
