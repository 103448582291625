import { useEffect, useState, useRef } from "react";
import classes from "./Four.module.scss";
import img1 from "../../../assets/img/HomePage/4/1.png";
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Four = () => {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/employers");
    setTimeout(() => {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#mainForm").offset().top,
        },
        2000
      );
    }, 200);
  }
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        x: 0,

        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top center",
          scrub: 5,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.four, "mb"].join(" ")} ref={col1}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fourRow, ""].join(" ")}>
            <div
              className={[classes.fourRowLeft, ""].join(" ")}
              ref={monetRef1}
            >
              <img src={img1} alt="" />
            </div>
            <div className={[classes.fourRowRight, ""].join(" ")}>
              <p className={[classes.fourRowRightText, "font-16"].join(" ")}>
                Ми приділяємо особливу увагу розумінню бізнес-потреб клієнта і
                прагнемо знайти ідеальну відповідність між вимогами вакансії та
                навичками кандидатів.
              </p>
              <p className={[classes.fourRowRightText, "font-16"].join(" ")}>
                Співпраця з BNB Solution дає можливість вашій компанії
                зосередитися на основній діяльності, дозволяючи нам піклуватися
                про пошук найкращих кандидатів для вашого зростання та розвитку.
              </p>
              <p className={[classes.fourRowRightText, "font-16"].join(" ")}>
                Зверніться до нас уже сьогодні та довірте свої потреби в наймі
                професіоналам, щоб побудувати сильну команду та досягти нових
                висот у вашому бізнесі.
              </p>
              <div
                className={[classes.fourRowRightBtn, ""].join(" ")}
                onClick={() => {
                  handleClick();
                }}
              >
                <Button>Замовити консультацію</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
