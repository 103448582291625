import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import classes from "./EmployersPage.module.scss";
import Three from "../../components/CandidatesComponents/Three/Three";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import ChooseAccount from "../../components/EmployersComponents/ChooseAccount/ChooseAccount";
import Slider from "../../components/EmployersComponents/Slider/Slider";
const EmployersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Роботодавцям</title>
        <meta name="title" content="Чому Обрати Нас для Пошуку Кандидата" />
        <meta
          property="og:title"
          content="Чому Обрати Нас для Пошуку Кандидата"
        />
        <meta
          property="twitter:title"
          content="Чому Обрати Нас для Пошуку Кандидата"
        />

        <meta
          name="description"
          content="Ми розуміємо, що час є важливим ресурсом для роботодавців. Ми швидко реагуємо на запити, проводимо швидкий підбір кандидатів та забезпечуємо якісний процес найму. Це дозволяє вам економити час та зосереджуватися на основних аспектах вашого бізнесу."
        />
        <meta
          property="og:description"
          content="Ми розуміємо, що час є важливим ресурсом для роботодавців. Ми швидко реагуємо на запити, проводимо швидкий підбір кандидатів та забезпечуємо якісний процес найму. Це дозволяє вам економити час та зосереджуватися на основних аспектах вашого бізнесу."
        />
        <meta
          property="twitter:description"
          content="Ми розуміємо, що час є важливим ресурсом для роботодавців. Ми швидко реагуємо на запити, проводимо швидкий підбір кандидатів та забезпечуємо якісний процес найму. Це дозволяє вам економити час та зосереджуватися на основних аспектах вашого бізнесу."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className={[classes.employers, ""].join(" ")}>
        <Header />
        <ChooseAccount />
        <Slider />
        <Three />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default EmployersPage;
