import React from "react";
import classes from "./Button.module.scss";

const Button = ({ children, login }) => {
  return (
    <>
      {login ? (
        <div className={`${classes.button}`}>
          <span>{children}</span>
        </div>
      ) : (
        <div className={`${classes.button}`}>
          <span>{children}</span>
        </div>
      )}
    </>
  );
};

export default Button;
