import { useEffect, useState, useRef } from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/HomePage/2/1.png";
import img2 from "../../../assets/img/HomePage/2/2.png";
import img3 from "../../../assets/img/HomePage/2/3.png";
import img4 from "../../../assets/img/HomePage/2/4.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Second = () => {
  gsap.config({
    force3D: true,
  });
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const monetRef3 = useRef(null);
  const monetRef4 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        y: 0,
        duration: 0.5,
        delay: 0.3,
      });
      gsap.timeline().to(monetRef2.current, {
        y: 0,
        duration: 0.5,
        delay: 0.6,
      });
      gsap.timeline().to(monetRef3.current, {
        y: 0,
        duration: 0.5,
        delay: 0.9,
      });
      gsap.timeline().to(monetRef4.current, {
        y: 0,
        duration: 0.5,
        delay: 1.2,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.second, "mb"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div
              className={[classes.secondRowColumn, ""].join(" ")}
              ref={monetRef1}
            >
              <div className={[classes.secondRowColumnIcon, ""].join(" ")}>
                <img src={img1} alt="BNB Solution:" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                9 років на <br /> ринку праці
              </p>
            </div>
            <div
              className={[classes.secondRowColumn, ""].join(" ")}
              ref={monetRef2}
            >
              <div className={[classes.secondRowColumnIcon, ""].join(" ")}>
                <img src={img2} alt="BNB Solution:" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                400 + задоволених <br /> клієнтів
              </p>
            </div>
            <div
              className={[classes.secondRowColumn, ""].join(" ")}
              ref={monetRef3}
            >
              <div className={[classes.secondRowColumnIcon, ""].join(" ")}>
                <img src={img3} alt="BNB Solution:" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                Досвід співпраці з ТОП - <br /> компаніями України
              </p>
            </div>
            <div
              className={[classes.secondRowColumn, ""].join(" ")}
              ref={monetRef4}
            >
              <div className={[classes.secondRowColumnIcon, ""].join(" ")}>
                <img src={img4} alt="BNB Solution:" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                87 % кандидатів проходять <br /> випробовувальний термін і<br />
                залишаються на роботі
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
