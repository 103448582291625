import { useEffect, useState, useRef } from "react";
import classes from "./ChooseAccount.module.scss";
import img1 from "../../../assets/img/Candidates/2/1.png";
import img2 from "../../../assets/img/Candidates/2/2.png";
import img3 from "../../../assets/img/Candidates/2/3.png";
import img4 from "../../../assets/img/Candidates/2/4.png";
import img5 from "../../../assets/img/Candidates/2/5.png";
import arrow from "../../../assets/img/traders/right.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ChooseAccount = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const mainRef = useRef(null);

  const slideRef1 = useRef(null);
  const slideRef2 = useRef(null);
  const slideRef3 = useRef(null);
  const slideRef4 = useRef(null);
  const slideRef5 = useRef(null);

  const cards = [
    {
      id: 1,
      image: img1,
      title: "Доступ до вакансій",
      ref: slideRef1,
      desc: "Ми маємо широку базу клієнтів та партнерів, що відкриває доступ до різноманітних вакансій у різних галузях. Кандидатам пропонуються можливості для кар'єрного зростання та розвитку.",
    },
    {
      id: 2,
      image: img2,
      title: "Експертна підтримка",
      ref: slideRef2,
      desc: "Команда BNB Solution володіє глибокими знаннями ринку праці та вимогами різних індустрій. Вони можуть надати цінні поради та підтримку в підготовці резюме, підготовці до співбесіди та навичках ведення успішної кар'єри.",
    },
    {
      id: 3,
      image: img3,
      title: "Конфіденційність та довіра",
      ref: slideRef3,
      desc: "ми дотримується принципу конфіденційності у відносинах з кандидатами. Інформація про кандидатів, їхні навички та особисті дані розглядаються конфіденційно, а також застосовуються відповідні заходи безпеки для захисту даних.",
    },
    {
      id: 4,
      image: img4,
      title: "Відбір найкращих пропозицій",
      ref: slideRef4,
      desc: "ми допомагаємо кандидатам отримати доступ до якісних пропозицій від роботодавців. Ми, враховуючи вимоги та переваги кандидатів, допомагаємо узгодити умови праці та забезпечити прозору процедуру підбору персоналу.",
    },
    {
      id: 5,
      image: img5,
      title: "Розвиток професійних навичок",
      ref: slideRef5,
      desc: "ми пропонуємо кандидатам різноманітні можливості для підвищення кваліфікації та розвитку професійних навичок. Це можуть бути тренінги, семінари, вебінари або інші форми навчання, спрямовані на підвищення конкурентоспроможності кандидатів на ринку.",
    },
  ];

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(slideRef1.current, {
        y: 0,
        delay: 0.3,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 1,
        },
      });
      gsap.timeline().to(slideRef2.current, {
        y: 0,
        delay: 0.5,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 2,
        },
      });
      gsap.timeline().to(slideRef3.current, {
        y: 0,
        delay: 0.7,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 3,
        },
      });
      gsap.timeline().to(slideRef4.current, {
        y: 0,
        delay: 0.9,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 4,
        },
      });
      gsap.timeline().to(slideRef5.current, {
        y: 0,
        delay: 1.1,
        duration: 1,
        scrollTrigger: {
          trigger: mainRef.current,
          end: "+=300px",
          scrub: 5,
        },
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <section
      className={[classes.chooseAccount, "mt mb"].join(" ")}
      ref={mainRef}
    >
      <div className="container">
        <div className={[classes.chooseAccountRow, ""].join(" ")}>
          <div className={[classes.chooseAccountRowLeft, ""].join(" ")}>
            <h2
              className={[classes.chooseAccountRowLeftTitle, "font-36"].join(
                " "
              )}
            >
              Чому обрати нас для пошуку роботи
            </h2>
          </div>
          <div className={[classes.chooseAccountRowRight, ""].join(" ")}>
            <div className={classes.pagination}>
              <img
                ref={prevRef}
                className={classes.pagPrev}
                src={arrow}
                alt=""
              />
              <img
                ref={nextRef}
                className={classes.pagNext}
                src={arrow}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={classes.swiperWr}>
          <Swiper
            modules={[Pagination, Navigation]}
            className="chooseAccountTypeSwiper"
            spaceBetween={20}
            slidesPerView={"auto"}
            pagination={true}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }}
          >
            {cards.map((card) => (
              <SwiperSlide
                key={card.id}
                className="chooseAccountTypeSwiperSlide"
              >
                <div className={classes.card} ref={card.ref}>
                  <div className={[classes.cardItem, ""].join(" ")}>
                    <div className={[classes.cardItemImg, ""].join(" ")}>
                      <img src={card.image} alt="" />
                    </div>
                    <h2
                      className={[classes.cardItemTitle, "font-24"].join(" ")}
                    >
                      {card.title}
                    </h2>
                    <p className={[classes.cardItemDesc, "font-16"].join(" ")}>
                      {card.desc}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ChooseAccount;
